import React from "react";
import {
  DeleteByNumber,
  SearchByNumber,
} from "../../../features/modifyNumberApi";
import { useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../components/common/Loader";
import ComponentCustomModal from "../../../components/common/customModal/ComponentCustomModal";

const DeleteByMobileModal = ({ show, setShow }) => {
  //get current use Role
  const currentUserRole = useSelector(
    (state) => state.persistedReducer.auth.role
  );

  const [customer, setCustomer] = useState("");
  const [mobile, setMobile] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //search api call function
  const SearchHandler = () => {
    SearchByNumber(mobile, setCustomer, setIsLoading);
  };

  //delete api call function
  const deleteHandler = () => {
    const confirm = window.confirm("Are you sure to Delete");
    if (confirm) DeleteByNumber(mobile, setIsLoading, setCustomer);
  };
  return (
    <>
      <ComponentCustomModal
        show={show}
        setShow={setShow}
        centered={true}
        size="md"
        header="User Mobile number found and delete"
        footer={
          <div>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => setShow(false)}
            >
              Close
            </button>
          </div>
        }
      >
        <div className="form-group">
          <div className="d-flex">
            <input
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              type="number"
              className="form-control me-2 w-100"
            />
            <button
              type="button"
              onClick={SearchHandler}
              className="btn btn-primary btn-sm float-end"
            >
              {isLoading ? <Loader /> : "Search"}
            </button>
          </div>

          {Object.keys(customer)?.length !== 0 ? (
            <>
              <h5 className="mt-3">
                {customer?.user?.role.toUpperCase()} Found
              </h5>
              <h5>Name : {customer?.profile?.name}</h5>
              <h5>Mobile : {customer?.profile?.mobile}</h5>
              {(currentUserRole === "superadmin" ||
                currentUserRole === "admin") &&
                customer?.user?.role === "customer" && (
                  <button
                    type="button"
                    onClick={deleteHandler}
                    className="btn btn-danger btn-sm py-1"
                  >
                    Delete
                  </button>
                )}
            </>
          ) : (
            ""
          )}
        </div>
      </ComponentCustomModal>
    </>
  );
};

export default DeleteByMobileModal;
