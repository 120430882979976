export const ispOwnerPermission = (perm) => {
  const permission = [
    {
      id: 1,
      value: "genCustomerId",
      label: "Customer Id Auto Generate",
      isChecked: perm?.genCustomerId,
    },
    {
      id: 2,
      value: "updateCustomerBalance",
      label: "Update Customer Balance",
      isChecked: perm?.updateCustomerBalance,
    },
    {
      id: 3,
      value: "mikrotikDelete",
      label: "Mikrotik Delete",
      isChecked: perm?.mikrotikDelete,
    },
    {
      id: 4,
      value: "promiseDate",
      label: "Promise Date",
      isChecked: perm?.promiseDate,
    },
    {
      id: 5,
      value: "reportDelete",
      label: "Report Delete",
      isChecked: perm?.reportDelete,
    },
    {
      id: 6,
      value: "expenditureDelete",
      label: "Expenditure Delete",
      isChecked: perm?.expenditureDelete,
    },
    {
      id: 7,
      value: "bulkAreaEdit",
      label: "Bulk Area Edit",
      isChecked: perm?.bulkAreaEdit,
    },
    {
      id: 8,
      value: "bulkStatusEdit",
      label: "Bulk Status Edit",
      isChecked: perm?.bulkStatusEdit,
    },
    {
      id: 9,
      value: "bulkBillingCycleEdit",
      label: "Bulk BillingCycle Edit",
      isChecked: perm?.bulkBillingCycleEdit,
    },
    {
      id: 10,
      value: "bulkPromiseDateEdit",
      label: "Bulk Promise Date Edit",
      isChecked: perm?.bulkPromiseDateEdit,
    },
    {
      id: 11,
      value: "bulkAutoDisableEdit",
      label: "Bulk Auto Disable Edit",
      isChecked: perm?.bulkAutoDisableEdit,
    },
    {
      id: 12,
      value: "bulkPackageEdit",
      label: "Bulk Package Edit",
      isChecked: perm?.bulkPackageEdit,
    },
    {
      id: 13,
      value: "bulkTransferToReseller",
      label: "Bulk Transfer To Reseller",
      isChecked: perm?.bulkTransferToReseller,
    },
    {
      id: 14,
      value: "bulkCustomerDelete",
      label: "Bulk Customer Delete",
      isChecked: perm?.bulkCustomerDelete,
    },
    {
      id: 15,
      value: "bulkCustomerMikrotikUpdate",
      label: "Bulk Customer Mikrotik Update",
      isChecked: perm?.bulkCustomerMikrotikUpdate,
    },
    {
      id: 16,
      value: "customerAutoConnection",
      label: "Customer Auto Connection",
      isChecked: perm?.customerAutoConnection,
    },
    {
      id: 17,
      value: "bulkCustomerRecharge",
      label: "Bulk Customer Recharge",
      isChecked: perm?.bulkCustomerRecharge,
    },
    {
      id: 18,
      value: "addCustomerWithMobile",
      label: "Add Customer with Mobile",
      isChecked: perm?.addCustomerWithMobile,
    },
    {
      id: 19,
      value: "instantRechargeBillPrint",
      label: "Instant Recharge Bill Print",
      isChecked: perm?.instantRechargeBillPrint,
    },
    {
      id: 20,
      value: "inActiveCustomerDelete",
      label: "In-Active/Offlie Customer Delete",
      isChecked: perm?.inActiveCustomerDelete,
    },
    {
      id: 21,
      value: "hasReseller",
      label: "Reseller Add",
      isChecked: perm?.hasReseller,
    },
    {
      id: 22,
      value: "multipleManager",
      label: "Multiple Manager",
      isChecked: perm?.multipleManager,
    },
    {
      id: 23,
      value: "resellerCustomerBulkStatusEdit",
      label: "Reseller Customer Bulk Status Edit",
      isChecked: perm?.resellerCustomerBulkStatusEdit,
    },
    {
      id: 24,
      value: "resellerCustomerBulkPromiseDateEdit",
      label: "Reseller Customer Bulk Promise Date Edit",
      isChecked: perm?.resellerCustomerBulkPromiseDateEdit,
    },
    {
      id: 25,
      value: "resellerCustomerBulkBillingCycleEdit",
      label: "Reseller Customer Bulk Billing Cycle Edit",
      isChecked: perm?.resellerCustomerBulkBillingCycleEdit,
    },
    {
      id: 26,
      value: "dailyRechargeFeature",
      label: "Daily Recharge Feature",
      isChecked: perm?.dailyRechargeFeature,
    },
    {
      id: 27,
      value: "unpaidCustomerBulkSms",
      label: "Unpaid Customer Bulk SMS",
      isChecked: perm?.unpaidCustomerBulkSms,
    },
    {
      id: 28,
      value: "poleBox",
      label: "Pole Box",
      isChecked: perm?.poleBox,
    },
    {
      id: 29,
      value: "showCustomerPanelPackage",
      label: "Show Customer Panel Package",
      isChecked: perm?.showCustomerPanelPackage,
    },
    {
      id: 30,
      value: "resellerMinusBalance",
      label: "Reseller Minus Balance",
      isChecked: perm?.resellerMinusBalance,
    },
    {
      id: 31,
      value: "resellerCustomerBulkAutoConnectionEdit",
      label: "Reseller Customer Bulk Auto Connection Edit",
      isChecked: perm?.resellerCustomerBulkAutoConnectionEdit,
    },
    {
      id: 32,
      value: "resellerCustomerBulkAreaEdit",
      label: "Reseller Customer Bulk Area Edit",
      isChecked: perm?.resellerCustomerBulkAreaEdit,
    },
    {
      id: 33,
      value: "resellerCustomerBulkPaymentStatusEdit",
      label: "Reseller Customer Bulk Payment Status Edit",
      isChecked: perm?.resellerCustomerBulkPaymentStatusEdit,
    },
    {
      id: 34,
      value: "bulkPaymentStatusEdit",
      label: "Bulk Payment Status Edit",
      isChecked: perm?.bulkPaymentStatusEdit,
    },
    {
      id: 35,
      value: "customerInvoice",
      label: "Customer Invoice",
      isChecked: perm?.customerInvoice,
    },
    {
      id: 36,
      value: "dashboardProbabilityAmountWithNewCustomer",
      label: "Dashboard Probability AmountWith NewCustomer",
      isChecked: perm?.dashboardProbabilityAmountWithNewCustomer,
    },
    {
      id: 37,
      value: "fixedBillingCycle",
      label: "Fixed BillingCycle Date",
      isChecked: perm?.fixedBillingCycle,
    },
    {
      id: 38,
      value: "withoutMikrotikMinusCustomerBalance",
      label: "Without Mikrotik Customer Minus Balance",
      isChecked: perm?.withoutMikrotikMinusCustomerBalance,
    },
    {
      id: 39,
      value: "depositUpdate",
      label: "Deposit Update",
      isChecked: perm?.depositUpdate,
      info: "ম্যানেজার এবং কালেক্টর আদায়কৃত পরিমাণ ডিপোজিট করার পরে, গ্রাহকের মাসিক বিল রিপোর্ট মুছে ফেলার ফলে মাইনাস ব্যালেন্স তৈরি হবে। তারপর পরে ডিপোজিট করলে দেবে (ত্রুটি/error)। পারমিশনের মাধ্যমে, মাইনাস ব্যালেন্স অবশ্যই ডিপোজিট রিপোর্টের পরিমাণ থেকে বিয়োগ করতে হবে।",
      // info: "After the manager and collector have deposited the amount recovered, Deleting a customer's monthly bill report will result in a minus balance. Then if you deposit later it will give (error). By permission, the minus balance must be subtracted from the deposit report amount.",
    },
    {
      id: 40,
      value: "networkDiagram",
      label: "Network Diagram",
      isChecked: perm?.networkDiagram,
    },
  ];
  return permission;
};
