import { ErrorMessage, Field, useField } from "formik";
import React from "react";
import DatePicker from "react-datepicker";
import InformationTooltip from "./tooltipInformation/InformationTooltip";

export const FtextField = ({ label, validation, ...props }) => {
  const [field, meta, { setValue }] = useField(props);

  return (
    <div>
      <label htmlFor={field.name} className="changeLabelFontColor">
        {label} {validation && <span className="text-danger me-4">*</span>}
        {props?.info && <InformationTooltip data={props?.info} />}
      </label>

      {/* field as key base */}
      {props?.component ? (
        props.component === "customerStatus" ? (
          <div
            className={`${
              props?.className ? props?.className : "displayGrid3"
            }`}
          >
            {props?.inputField.map(
              (option) =>
                option?.isVisible && (
                  <div className="form-check form-check-inline mt-0">
                    <Field
                      className="form-check-input"
                      {...field}
                      {...option}
                      onChange={(e) => {
                        field.onChange(e);
                        props?.onChange(e);
                      }}
                      autoComplete="off"
                    />
                    <label className="form-check-label" htmlFor={option?.id}>
                      {option?.label}
                    </label>
                  </div>
                )
            )}
          </div>
        ) : props.component === "addStaff" ? (
          <div className="displayGrid3">
            {props?.inputField.map(
              (option) =>
                option?.isVisible && (
                  <div className="form-check form-check-inline mt-0">
                    <Field
                      className="form-check-input"
                      {...field}
                      {...option}
                      onChange={(e) => {
                        field.onChange(e);
                        props?.onChange(e);
                      }}
                      autoComplete="off"
                    />
                    <label className="form-check-label" htmlFor={option?.id}>
                      {option?.label}
                    </label>
                  </div>
                )
            )}
          </div>
        ) : (
          <DatePicker
            className="form-control mw-100"
            {...field}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            onChange={props?.onChange}
            autoComplete="off"
          />
        )
      ) : props?.as ? (
        <Field
          className={`form-select shadow-none mw-100 mt-0 ${
            meta.touched && meta.error && "is-invalid"
          }`}
          {...field}
          {...props}
          autoComplete="off"
          onChange={(e) => {
            field.onChange(e);
            props?.onChange && props?.onChange(e);
          }}
        >
          <option>{props.firstOptions}</option>
          {Array.isArray(props.options) &&
            props.options.map((opt) => (
              <option
                key={opt[props?.valueAccessor]}
                selected={field?.value === opt[props?.valueAccessor]}
                value={opt[props?.valueAccessor]}
              >
                {opt[props?.textAccessor]}
              </option>
            ))}
        </Field>
      ) : (
        <Field
          className={`form-control shadow-none ${
            meta.touched && meta.error && "is-invalid"
          }`}
          {...field}
          {...(props !== undefined)}
          disabled={props?.disabled}
          value={props?.value ? props.value : field.value}
          onChange={(e) => {
            field.onChange(e);
            props?.onChange && props?.onChange(e);
          }}
          placeholder={props?.placeholder}
          autoComplete="off"
        ></Field>
      )}

      <ErrorMessage
        component="div"
        name={field.name}
        className="errorMessage text-danger mb-0"
      />
    </div>
  );
};
